// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_eVacNP28R3hnaGI6oT", // colorize
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/00g291a3A0l04AE14y", // colorize

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Colorize Image AI 🎭",
    HOME_PAGE_TITLE: "Home - Colorize Image AI 🎭",
    DASHBOARD_PAGE_TITLE: "Home - Colorize Image AI 🎭",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Colorize Image AI 🎭",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to add color to old photos, choose your image file and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "colorize_img_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/colorize-img-ai-1",

    // ... add more strings as needed
};



export default strings;